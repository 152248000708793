import { FC } from "react";
import css from "./index.module.css";

import icon from "./default-icon.png";

interface UserRefCardProps {
  mail: string;
  usdt?: string;
  level?: string;
  serverIcon?: string;
}

const UserRefCard: FC<UserRefCardProps> = ({
  mail,
  usdt,
  level,
  serverIcon,
}) => {
  return (
    <div className={css.wrapper}>
      <div className={css.left}>
        <img
          src={serverIcon && serverIcon?.length > 0 ? serverIcon : icon}
          alt="icon"
          className={css.icon}
          width={"40px"}
          height={"40px"}
        ></img>
        <div className={css.userInfo}>
          <p className={css.mail}>{mail}</p>
          <p className={css.level}>{level}</p>
        </div>
      </div>
      <div>
        <p className={css.usdt}>{usdt}</p>
      </div>
    </div>
  );
};

export default UserRefCard;
