import { FC, FunctionComponent } from "react";
import css from "./ProfileButton.module.css";

interface ProfileButtonProps {
  value: string;
  Icon?: FunctionComponent;
  onClick?: () => void;
}

const ProfileButton: FC<ProfileButtonProps> = ({ value, Icon, onClick }) => {
  return (
    <div className={css.wrapper} onClick={onClick}>
      {Icon && <Icon />} <p>{value}</p>
    </div>
  );
};

export default ProfileButton;
