export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export const getWeeksPassed = (startDate: string): number => {
  const givenDate = new Date(startDate);
  const currentDate = new Date();
  const diffInMilliseconds = currentDate.getTime() - givenDate.getTime();
  const millisecondsInAWeek = 1000 * 60 * 60 * 24 * 7;
  const weeksPassed = Math.floor(diffInMilliseconds / millisecondsInAWeek);

  return weeksPassed;
};
