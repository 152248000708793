import { FC, useEffect, useState } from "react";
import css from "./index.module.css";
import UserRefCard from "../../UserReFCard";
import { Refers, RefersData } from "../../../screens/Referal";
import { getCookie } from "../../../helpers/cockieHelper";
import { api } from "../../../api/api";
import { formatNumber } from "../../../utils/numberFormater";
import { formatDate } from "../../../utils/dateFormater";
import { HistoryDtata } from "../../../api/interfaces/history";
interface RefListProps {
  activeTab: string;
}

const RefList: FC<RefListProps> = ({ activeTab }) => {
  const [refData, setRefData] = useState<RefersData>({} as RefersData);
  const [history, setHistory] = useState<HistoryDtata>({} as HistoryDtata);

  const getAllRefers = async () => {
    try {
      const response = await api.get<RefersData>(`/profile/me/referrals/`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getCookie("key")?.toString()} `,
          "Content-Type": "application/json",
        },
      });
      if (response.data) setRefData(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };
  const getFirstLevelRefers = async () => {
    try {
      const response = await api.get<RefersData>(
        `/profile/me/referrals/?depth=1&page=1&page_size=500`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) setRefData(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  const getHistory = async () => {
    try {
      const response = await api.get<HistoryDtata>(
        `/profile/me/history/?type=REFERRAL_FEES&page=1&page_size=500`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) setHistory(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    console.log("his", history);
  }, [history]);
  useEffect(() => {
    switch (activeTab) {
      case "first_level":
        getFirstLevelRefers();
        break;
      case "all_refers":
        getAllRefers();
        break;
      case "history":
        getHistory();
        break;
      default:
        console.log("noting");
    }
  }, [activeTab]);

  const RefListRender = () => {
    switch (activeTab) {
      case "first_level":
        return (
          <div className={css.refUserCards}>
            {refData?.results?.map((item, index) => (
              <div key={index}>
                <UserRefCard
                  mail={item.email}
                  usdt={`${item.total_bundles} ЭНТЕРОВ`}
                  level={`${item.depth} level`}
                  serverIcon={item.profile_pic ? item.profile_pic : ""}
                />
              </div>
            ))}
          </div>
        );
      case "all_refers":
        return (
          <div className={css.refUserCards}>
            {refData?.results?.map((item, index) => (
              <div key={index}>
                <UserRefCard
                  mail={item.email}
                  level={`${item.depth} level`}
                  serverIcon={item.profile_pic ? item.profile_pic : ""}
                />
              </div>
            ))}
          </div>
        );
      case "history":
        return (
          <div className={css.refUserCards}>
            {history?.results?.map((item, index) => (
              <div key={index}>
                <UserRefCard
                  mail={item.detail.email}
                  usdt={`${formatNumber(+item.change)} USDT`}
                  level={formatDate(item.created_at)}
                  serverIcon={
                    item.detail.avatar_url ? item.detail.avatar_url : ""
                  }
                />
              </div>
            ))}
          </div>
        );
    }
  };
  return <div className={css.wrapper}>{RefListRender()}</div>;
};
export default RefList;
