import { FC } from "react";
import css from "./index.module.css";
import cn from "classnames";
import { ReactComponent as WalletIcon } from "../../../images/icons/wallet.svg";
import { ReactComponent as ProfileIcon } from "../../../images/icons/user.svg";

interface RefCardProps {
  users: string;
  money: string;
  color: string;
  description: string;
  partners?: string;
  onClick: () => void;
}

const RefCard: FC<RefCardProps> = ({
  users,
  money,
  color,
  description,
  partners,
  onClick,
}) => {
  return (
    <div
      className={cn(css.wrapper, {
        [css.green]: color === "darck-green",
        [css.purple]: color === "purple",
        [css.red]: color === "red",
        [css.gold]: color === "gold",
      })}
      onClick={onClick}
    >
      <div
        className={cn({
          [css.greenImage]: color === "darck-green",
          [css.purpleImage]: color === "purple",
          [css.redImage]: color === "red",
          [css.goldImage]: color === "gold",
        })}
      ></div>
      <div className={css.descriptionWrap}>
        <p className={css.description}>{description.toUpperCase()}</p>
        {partners && <p className={css.partners}>{partners.toUpperCase()}</p>}
      </div>

      <div className={css.customLine}></div>
      <div className={css.footerWrap}>
        <p className={css.footerDescription}>ДОХОД ПО БОНУСУ</p>
        <p className={css.footerAmount}>${money}</p>
      </div>
    </div>
  );
};
export default RefCard;
