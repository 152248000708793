import { FC } from "react";
import RefCard from "../UI/RefCard";
import { formatNumber } from "../../utils/numberFormater";
import { IRefCardsList, REF_CARDS } from "./constants";

const RefCardsList: FC<IRefCardsList> = ({
  userData,
  setRefScreen,
  setDetailedState,
  refersData,
}) => {
  return (
    <div className="flex flex-col gap-6 items-center mb-[75px]">
      {REF_CARDS.map((item, index) => {
        return (
          <div key={index}>
            <RefCard
              users={`${refersData?.count || 0}`}
              money={
                index === 0
                  ? formatNumber(+userData.bonus_balance)
                  : formatNumber(item.money)
              }
              color={item.color}
              description={item.description}
              partners={item?.partners}
              onClick={() => {
                setDetailedState((prev) => ({
                  ...prev,
                  color: item.color,
                  goBack: () => setRefScreen("ref"),
                  title: item.description,
                  users: `${refersData?.count || 0}`,
                  money: formatNumber(item.money),
                  description: item.detailed,
                  bonuseTableData: item.tableData,
                }));
                setRefScreen("detailedRef");
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RefCardsList;
