import { FC } from "react";
import css from "./TrideCard.module.css";
import cn from "classnames";
import { ReactComponent as CheckMark } from "./icons/chekMark.svg";
import { ReactComponent as EmptyMark } from "./icons/empty.svg";

import ShareButton from "../UI/Buttons/ShareButton";
import { formatNumber } from "../../utils/numberFormater";

interface TradeCardProps {
  name: string;
  color: string;
  bothCount: number;
  maxCount: number;
  onClick: () => void;
  description?: string;
  size: string;
  procent: string;
  totalEarning: number;
  price: number;
  userRef?: string;
  deposite: number;
}

const TradeCard: FC<TradeCardProps> = ({
  name,
  color,
  bothCount,
  onClick,
  description,
  size,
  maxCount,
  procent,
  totalEarning,
  price,
  userRef,
  deposite,
}) => {
  const inviteText = `❇️ Делюсь своими успехами ❇️
    
  Присоединяйся к MEME по реферальной ссылке👾
  
  Депозит: $${deposite.toFixed(2)}
  Заработано: $${+totalEarning.toFixed(2)}
  Доходность: ${procent}%
            `;
  return (
    <div onClick={() => onClick()} className="z-0">
      <div
        className={cn(css.wrapper, {
          [css.small]: size === "smal",
          [css.bigBG]: size === "big",
        })}
      >
        <div
          className={cn(css.blur, {
            [css.backgroundBlueBig]: color === "blue",
            [css.backgroundRedBig]: color === "red",
            [css.backgroundPurpleBig]: color === "purple",
            [css.backgroundGreenBig]: color === "green",
            [css.backgroundGoldBig]: color === "gold",
            [css.backgroundDarckGreendBig]: color === "darck-green",
            [css.backgroundLigthPinkBig]: color === "pink",
            [css.backgroundLigthOrangeBig]: color === "orange",
            [css.backgroundLigthGreenBig]: color === "ligth-green",
          })}
        ></div>

        <div className={css.header}>
          <h2 className={css.label}>{name.toUpperCase()}</h2>
          {userRef && (
            <ShareButton inviteText={inviteText} userRefLink={userRef} />
          )}
        </div>
        {size === "smal" && (
          <>
            <div className={css.statistic}>
              <div>
                <p className={css.statisticTitle}>ДОХОДНОСТЬ В НЕДЕЛЮ</p>
                <p className={css.statisticAmount}>{procent}%</p>
              </div>
              <div>
                <p className={css.statisticTitle}>ВСЕГО ЗАРАБОТАНО</p>
                <p className={css.statisticAmount}>
                  ${formatNumber(totalEarning)}
                </p>
              </div>
            </div>
            <div className={css.customLine}></div>
          </>
        )}

        {description && <p className={css.description}>{description}</p>}

        {size === "big" && (
          <div className={css.statisticWrap}>
            <div className={css.statisticBig}>
              <div>
                <p className={css.statisticTitle}>ДЕПОЗИТ:</p>
                <p className={css.statisticAmount}>${deposite}</p>
              </div>
              <div>
                <p className={css.statisticTitle}>ДОСТУПНО ДЛЯ ПОКУПКИ</p>
                <p className={cn(css.statisticAmount, css.right)}>
                  ${formatNumber(price - deposite)}
                </p>
              </div>
            </div>
            <div className={css.statisticBig}>
              <div>
                <p className={css.statisticTitle}>ДОХОДНОСТЬ В НЕДЕЛЮ</p>
                <p className={css.statisticAmount}>{procent}%</p>
              </div>
              <div>
                <p className={css.statisticTitle}>ВСЕГО ЗАРВБОТАНО</p>
                <p className={cn(css.statisticAmount, css.right)}>
                  ${formatNumber(totalEarning)}
                </p>
              </div>
            </div>
          </div>
        )}
        {size === "big" && <div className={cn(css.customLine, "mb-3")}></div>}
        <div className={css.footerWraper}>
          <span className={css.footer}>ДОСТУПНО К ПОКУПКЕ</span>
          <div
            className={cn(css.labels, {
              [css.markBlue]: color === "blue",
              [css.markRed]: color === "red",
              [css.markPurpule]: color === "purple",
              [css.markGreen]: color === "green",
              [css.markDackGreen]: color === "darck-green",
              [css.markLightGreen]: color === "ligth-green",
              [css.markOrange]: color === "orange",
              [css.markPink]: color === "pink",
              [css.markGold]: color === "gold",
            })}
          >
            {[...Array(maxCount - bothCount)].map((_, index) => (
              <CheckMark key={index} />
            ))}
            {[...Array(bothCount)].map((_, index) => (
              <EmptyMark key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeCard;
