import { FC, useState } from "react";
import ChangePasswordScreem from "../ChangePasswordScreen/ChangePasswordScreen";

import SettingsScreen from "../Settings";

interface ProfileProps {
  setFooterVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Profile: FC<ProfileProps> = ({ setFooterVisible }) => {
  const [window, setWindow] = useState<string>("settings");

  const renderProfile = () => {
    switch (window) {
      case "settings":
        setFooterVisible(true);
        return (
          <SettingsScreen
            setFooterVisible={setFooterVisible}
            setWindow={setWindow}
          />
        );
      case "change_pin":
        return (
          <ChangePasswordScreem
            setScreen={() => {
              setWindow("settings");
            }}
          />
        );
    }
  };
  return <>{renderProfile()}</>;
};
export default Profile;
