import { FC } from "react";
import ShareIcon from "../../../../images/icons/share.svg";
import { initUtils } from "@telegram-apps/sdk";
const utils = initUtils();

interface ShareButtonProps {
  inviteText: string;
  userRefLink: string;
}

const handleClick = (
  event: React.MouseEvent<HTMLElement>,
  inviteText: string,
  userRefLink: string
) => {
  event.stopPropagation();
  utils.shareURL(userRefLink, inviteText);
};

const ShareButton: FC<ShareButtonProps> = ({ inviteText, userRefLink }) => {
  return (
    <div
      className="cursor-pointer z-10"
      onClick={(event) => {
        handleClick(event, inviteText, userRefLink);
      }}
    >
      <img src={ShareIcon} alt="отправить" />
    </div>
  );
};
export default ShareButton;
