import { FC, useEffect, useRef, useState } from "react";

import css from "./modal.module.css";
import Button from "../../Buttons/Button";
import cn from "classnames";
import LoaderIcon from "../../../../images/icons/loader-icon.component";
import SuccesIcon from "../../../../images/icons/succes-icon.component";
import FalledIcon from "../../../../images/icons/falled-icon.component";
import { ModalProps } from "../../../../screens/interfaces";
import ModalTableHeaders from "../../../ModalTableHeaders";
import { BonucesTableType } from "../../../RefCardsList/constants";

const Modal: FC<ModalProps> = ({
  onClick,
  type,
  amount,
  color,
  position,
  bonuseTableData,
  onClose,
}) => {
  const [headers, setHeaders] = useState<BonucesTableType[]>(
    bonuseTableData as BonucesTableType[]
  );
  const [activeTab, setActiveTab] = useState<number>(0);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        onClick
      ) {
        onClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClick]);

  const handleClicHeaders = (index: number) => {
    const updatedHeaders = headers.map((item, i) => {
      const isActive = i === index;
      if (isActive) setActiveTab(index);
      return {
        ...item,
        isActive,
      };
    });

    setHeaders(updatedHeaders);
  };

  const bodyRender = () => {
    switch (type) {
      case "premium":
        return (
          <>
            <h1 className={css.title}>Купите DAO, для доступа к слотам</h1>

            <Button onClick={onClick}>Купить DAO {amount ? amount : ""}</Button>
          </>
        );
      case "pending":
        return (
          <>
            <div className={css.iconWrap}>
              <div className={css.loaderWrap}>
                <LoaderIcon />
              </div>
            </div>
            <div className={css.titleWrap}>
              <span className={css.title}> Ожидаем оплату</span>
            </div>
          </>
        );
      case "success":
        return (
          <>
            <div className={css.iconWrap}>
              <SuccesIcon />
            </div>
            <div className={css.titleWrap}>
              <p className={css.title}>Успешная оплата</p>
            </div>
            <Button onClick={onClick}>Ок</Button>
          </>
        );
      case "falled":
        return (
          <>
            <div className={css.iconWrap}>
              <FalledIcon />
            </div>
            <div className={css.titleWrap}>
              <span className={css.title}>Платеж не прошел</span>
            </div>
            <Button onClick={onClick} className={"bg-white"}>
              Повторить платеж
            </Button>
          </>
        );
      case "table":
        return (
          <div ref={modalRef}>
            <h1 className={css.tableHeader}>Таблица доходности</h1>
            <ModalTableHeaders
              handleClicHeaders={handleClicHeaders}
              headers={headers}
            />
            <div className={css.RefListWrapper}>
              {headers[activeTab] &&
                headers[activeTab].licenseData.map((item) => {
                  return (
                    <>
                      <div className={css.listElement}>
                        <div>
                          <p className={css.listTitle}>ЛИЧНАЯ ЛИЦЕНЗИЯ</p>
                          <p className={css.listAmount}>${item.money}</p>
                        </div>
                        <div>
                          <p className={cn(css.listTitle, css.right)}>БОНУС</p>
                          <p
                            className={cn(css.listAmount, {
                              [css.center]: item.procent === "x",
                            })}
                          >
                            {item.procent[0] === "x" ? "-" : item.procent}
                          </p>
                        </div>
                      </div>
                      <div></div>
                    </>
                  );
                })}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const modalWrapClass = cn(css.wrapper, {
    [css.blue]: type === "pending",
    [css.red]: type === "falled",
    [css.purpleBig]: type === "table" && color === "purple",
    [css.darckGreenBig]: type === "table" && color === "darck-green",
    [css.redBig]: type === "table" && color === "red",
    [css.goldBig]: type === "table" && color === "gold",
    [css.statick]: position === "statick",
    [css.relative]: position === "relative",
  });
  return (
    <div className={modalWrapClass}>
      <div className={css.hole}></div>
      {onClose && (
        <p className={css.close} onClick={onClose}>
          X
        </p>
      )}
      {bodyRender()}
    </div>
  );
};

export default Modal;
