import axios from "axios";

export const api = axios.create({
  baseURL: "https://memback.taskcomplete.ru/api/v1/",
});

export const apiEcvaring = axios.create({
  baseURL: "https://scam-pay-crypto.taskcomplete.ru",
  timeout: 18000,
});
