import { FC } from "react";
import css from "./BackButton.module.css";
import { ReactComponent as ArrowBack } from "./icons/arrowBack.svg";
import cn from "classnames";

export interface BackButtonProps {
  goBack: () => void;
  color?: string;
}

const BackButton: FC<BackButtonProps> = ({ goBack, color }) => {
  return (
    <div
      className={cn(css.wrapper, { [css.white]: color === "white" })}
      onClick={() => goBack()}
    >
      <ArrowBack />
      <span className={css.text}>ВЕРНУТЬСЯ</span>
    </div>
  );
};

export default BackButton;
