import { FC } from "react";
import css from "./index.module.css";
import cn from "classnames";
import Mascot from "../../images/svg/meme.svg";
import { formatNumber } from "../../utils/numberFormater";
import ShareButton from "../UI/Buttons/ShareButton";

interface MoneyStatisticProps {
  depositAmount: number;
  procent: string;
  cardIncome: number;
  date: string;
  userRefLink: string;
}
const MoneyStatistic: FC<MoneyStatisticProps> = ({
  depositAmount,
  procent,
  cardIncome,
  date,
  userRefLink,
}) => {
  const inviteText = `❇️ Делюсь своими успехами ❇️
    
    Присоединяйся к MEME по реферальной ссылке👾
    
    Дата: ${date}
    Депозит: $${depositAmount.toFixed(2)}
    Заработано: $${cardIncome.toFixed(2)}
    Доходность: ${procent}%
              `;
  return (
    <div className={css.wrap}>
      <div className={css.main}>
        <div className={css.moneyList}>
          <div>
            <p className={css.description}>
              {"Общая Cумма депозита".toLocaleUpperCase()}:
            </p>
            <p className={css.amount}>${formatNumber(depositAmount)}</p>
          </div>
          <div>
            <p className={css.description}>
              {"Заработано".toLocaleUpperCase()}:
            </p>
            <p className={cn(css.amount, css.amountGreen)}>
              ${formatNumber(cardIncome)}
            </p>
          </div>
          <div>
            <p className={css.description}>
              {"Доходность".toLocaleUpperCase()}:
            </p>
            <p className={css.amount}>{procent}%</p>
          </div>
        </div>

        <ShareButton userRefLink={userRefLink} inviteText={inviteText} />
      </div>

      <div className={css.footer}>
        <p>Дата: {date}</p>
      </div>
      <div>
        <img src={Mascot} alt="Персонаж" className={css.memeImg} />
      </div>
    </div>
  );
};

export default MoneyStatistic;
