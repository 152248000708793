import { FC } from "react";
import css from "./index.module.css";
import cn from "classnames";
import { ReferalHeadersElement } from "../../screens/Referal/Detailed";
interface ReferalListHeaderProps {
  headers: ReferalHeadersElement[];
  handleClicHeaders: (index: string) => void;
  activeTab: string;
}

const ReferalListHeader: FC<ReferalListHeaderProps> = ({
  headers,
  handleClicHeaders,
  activeTab,
}) => {
  return (
    <div className={css.wrapper}>
      <div className={css.elementsWrapper}>
        {headers.map((item) => {
          return (
            <p
              key={item.value}
              className={cn(css.element, {
                [css.active]: item.isActive === true,
              })}
              onClick={() => {
                handleClicHeaders(item.value);
              }}
            >
              {item.label}
            </p>
          );
        })}
      </div>
      <div className={css.progressBar}>
        <div
          className={cn(css.progressLabel, {
            [css.progressLabelEnd]: activeTab === "history",
            [css.progressLabelMidle]: activeTab === "all_refers",
            [css.progressLabelStart]: activeTab === "first_level",
          })}
        ></div>
      </div>
    </div>
  );
};

export default ReferalListHeader;
