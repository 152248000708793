import React, { FC } from "react";
import Button from "../../components/UI/Buttons/Button/Button";
import TextInput from "../../components/UI/Inputs/TextInput/TextInput";
import Registration from "../../images/svg/registration.svg";
import { useState } from "react";
import { api } from "../../api/api";
import { getCookie, setCookie } from "../../helpers/cockieHelper";
import { UserData } from "../../App";
import cn from "classnames";

interface RegistrationScreenProps {
  setScreen: React.Dispatch<React.SetStateAction<string>>;
  userData: UserData;
  startParam: string;
}

const RegistrationScreen: FC<RegistrationScreenProps> = ({
  setScreen,
  userData,
  startParam,
}) => {
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [panding, setPanding] = useState<boolean>(false);
  const [isKeypoardOpen, setIsKeyBoardOpen] = useState<boolean>(false);

  // Регулярное выражение для проверки email
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSentCode = async () => {
    if (panding) return;
    if (!emailPattern.test(email)) {
      alert("Почта не коректна");
      return;
    }
    setPanding(true);
    try {
      const body = startParam
        ? {
            email: email,
            password1: "1234123412341234",
            password2: "1234123412341234",
            name: userData.name,
            account_type: "general",
            telegram_id: userData.id,
            invite_code: startParam,
          }
        : {
            email: email,
            password1: "1234123412341234",
            password2: "1234123412341234",
            name: userData.name,
            account_type: "general",
            telegram_id: userData.id,
          };

      await api.post("accounts/registration/", body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      alert("Код отправлен");
    } catch (error: any) {
      alert("Адресс почты некорректен или уже используется");
    }
    setPanding(false);
  };

  const verifyEmail = async () => {
    if (!code.length && code.length < 4) {
      alert("Код некоректен");
      return;
    }
    const body = {
      key: code,
    };

    try {
      const response = await api.post(
        "accounts/registration/account-confirm-email/",
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Login successful:", response.data.key);
      setCookie("email", email, 1000);
      setCookie("key", response.data.key, 1000);
      setCookie("password", "123123123", 1000);
      setCookie("name", userData.name, 1000);
    } catch (error: any) {
      console.error("Error during login:", error);
      alert("Данные не валидны");
    }
  };

  const handleLogin = async () => {
    if (!emailPattern.test(email) && !code) {
      alert("Не все обязательные поля заполненны или почта некоректна");
      return;
    }
    if (panding) return;
    setPanding(true);
    try {
      verifyEmail();
      const body = {
        telegram_id: userData.id,
        password: "1234123412341234",
      };
      const response = await api.post("accounts/login/", body, {});

      // Обработка успешного ответа
      setCookie("email", email, 7);
      setCookie("key", response.data.key, 7);
      setCookie("password", "123123123", 7);

      alert("успешно");

      setScreen("pin-code");
    } catch (error: any) {
      console.error("Error during login:", error);
      alert("Данные не валидны");
    }
    setPanding(false);
  };

  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <div className="w-full h-43vh ">
        <img
          src={Registration}
          alt="Картинка регистрации"
          className="w-full "
        />
      </div>
      <div
        className={cn(
          "flex flex-col w-full text-left uppercase px-6 pt-[29px]",
          { ["-mt-[45vh] bg-black bg-opacity-50"]: isKeypoardOpen }
        )}
      >
        <span className="text-[56px] mb-[40px]">Регистрация</span>
        <div className="flex flex-col gap-3 mb-8">
          <TextInput
            setIsKeyBoardOpen={setIsKeyBoardOpen}
            placehoder="EMAIL"
            value={email}
            onChange={setEmail}
            icon={
              <span
                onClick={() => handleSentCode()}
                className="uppercase w-[150px]"
              >
                Отправить код
              </span>
            }
          />
          <TextInput
            placehoder="ВВЕДИТЕ КОД ИЗ ПИСЬМА"
            value={code}
            onChange={setCode}
          />
        </div>

        <Button onClick={() => handleLogin()}>Продолжить</Button>
      </div>
    </div>
  );
};

export default RegistrationScreen;
