export const formatNumber = (number: number): string => {
  return number
    .toFixed(2)
    .replace(/\.00$/, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

// console.log(formatNumber(1));  "1"
// console.log(formatNumber(2000)); "2 000"
// console.log(formatNumber(5000000)); "5 000 000"
