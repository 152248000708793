import { FC, useState } from "react";
import css from "./index.module.css";
import crossIcon from "./svg/cross.svg";
import TextInput from "../UI/Inputs/TextInput";
import Button from "../UI/Buttons/Button";
import { HoverModalProps } from "./interfaces";
import TextArea from "../UI/TextArea";
import { api } from "../../api/api";
import { getCookie } from "../../helpers/cockieHelper";

const HoverModal: FC<HoverModalProps> = ({ close, type, setModalType }) => {
  const [amount, setAmount] = useState<string>("");
  const [wallet, setWallet] = useState<string>("");
  const [supportMessage, setSupportMessage] = useState<string>("");

  const handleSendToSupport = async (message: string) => {
    if (message.length < 1) {
      alert("Слишком кортоткое сообщение");
      return;
    }
    try {
      const response = await api.post(
        `/profile/me/support/`,
        { text: message },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `,
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        setModalType("success");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const RenderBody = () => {
    switch (type) {
      case "withdrawal":
        return (
          <>
            <h2 className={css.title}>ЗАКАЗАТЬ ВЫВОД</h2>
            <p className={css.description}>
              Чтобы получить вывод средств на свой счет, укажите сумму для
              вывода и Ваш кошелек в сети TRC-20
            </p>
            <div className={css.field}>
              <p className={css.label}>СУММА НА ВЫВОД</p>
              <TextInput
                mask="9999999999"
                maskChar=""
                classes={{
                  input:
                    "bg-gradient-to-r from-[#232323] to-[#3A3A3A] text-[19px] max-h-[62px] text-white ",
                }}
                placehoder="ВВЕДИТЕ СУММУ"
                value={amount}
                onChange={setAmount}
              />
            </div>
            <div className={css.field}>
              <p className={css.label}>КОШЕЛЕК TRC-20</p>
              <TextInput
                classes={{
                  root: "mb-[24]",
                  input:
                    "bg-gradient-to-r from-[#232323] to-[#3A3A3A] text-[19px] max-h-[62px] text-white",
                }}
                placehoder="ВВЕДИТЕ НОМЕР КОШЕЛЬКА"
                value={wallet}
                onChange={setWallet}
              />
            </div>
            <Button>Отправить</Button>
          </>
        );
      case "support":
        return (
          <>
            <h2 className={css.title}>Написать в поддержку</h2>
            <TextArea
              classes={{
                root: "mb-[24px]",
                input:
                  "bg-gradient-to-r from-[#232323] to-[#3A3A3A] text-[19px]  text-white p-[5px] max-h-[160px] ",
              }}
              value={supportMessage}
              onChange={setSupportMessage}
              placeholder={"Свяжитесь с нами"}
            />
            <Button onClick={() => handleSendToSupport(supportMessage)}>
              Отправить
            </Button>
          </>
        );
      case "success":
        return (
          <>
            <h2 className={css.title}>Успешно</h2>
            <Button onClick={() => close()}>ОК</Button>
          </>
        );
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.modal}>
        <img
          src={crossIcon}
          alt="выход"
          onClick={close}
          className={css.cancelIcon}
        />
        {RenderBody()}
      </div>
    </div>
  );
};

export default HoverModal;
