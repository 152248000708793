import { FC } from "react";

const LoaderIcon: FC = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_588_448)">
        <path
          d="M40 20V10"
          stroke="white"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.1"
          d="M54.1667 25.8332L61.3334 18.6665"
          stroke="white"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.3"
          d="M60 40H70"
          stroke="white"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.5"
          d="M54.1667 54.1665L61.3334 61.3332"
          stroke="white"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.7"
          d="M40 60V70"
          stroke="white"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.9"
          d="M25.8334 54.1665L18.6667 61.3332"
          stroke="white"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20 40H10"
          stroke="white"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.8334 25.8332L18.6667 18.6665"
          stroke="white"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_588_448">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LoaderIcon;
