import clsx from "clsx";
import React, { useState } from "react";

interface IClasses {
  root?: string;
  input?: string;
  label?: string;
  error?: string;
}

interface IProps {
  label?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  onFocus?: (newValue?: string) => void;
  onBlur?: (newValue?: string) => void;
  classes?: IClasses;
  error?: string;
  placeholder?: string;
  readOnly?: boolean;
  info?: React.ReactNode;
  icon?: React.ReactNode;
  setIsKeyBoardOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TextArea: React.FC<IProps> = ({
  onChange,
  onFocus,
  onBlur,
  value = "",
  label,
  classes = {},
  error,
  placeholder,
  readOnly,
  info,
  icon,
  setIsKeyBoardOpen,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    onChange?.(e.currentTarget.value);
  };

  const isIPhone = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const handleFocus: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
    onFocus?.(e.currentTarget.value);

    if (isIPhone && setIsKeyBoardOpen) {
      setIsKeyBoardOpen(true);
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
    onBlur?.(e.currentTarget.value);
    if (isIPhone && setIsKeyBoardOpen) {
      setIsKeyBoardOpen(false);
    }
  };

  return (
    <div
      className={clsx(
        "relative block w-full bg-[#3F3F3F] rounded-lg",
        classes.root,
        icon && "flex items-center"
      )}
    >
      {(label || info) && (
        <div className="flex justify-between mb-3">
          {label && (
            <span className={clsx("block text-[#fff] text-base")}>{label}</span>
          )}
          {info}
        </div>
      )}

      <textarea
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={clsx(
          "w-full text-[#fff] bg-[#3F3F3F] text-sm border-none h-[60px] rounded-lg outline-none px-6 py-4",
          classes.input
        )}
        disabled={readOnly}
        rows={4}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {icon && icon}

      {error && (
        <div className={clsx(classes.error, "text-red-500 text-xs mt-1")}>
          {error}
        </div>
      )}
    </div>
  );
};

export default TextArea;
