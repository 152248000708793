import React, { useEffect, useState } from "react";

import "./App.css";
import WelcomeScreen from "./screens/WelcomeScreen/WelcomeScreen";
import RegistrationScreen from "./screens/RegistrationScreen/RegistrationScreen";
import PinCodeScreen from "./screens/PinCodeScreen/PinCodeScreen";

import Authorized from "./screens/Authorized/Autorized";
import { api } from "./api/api";
import LoadingScreen from "./screens/Loading/LoadingScreen";
import { retrieveLaunchParams } from "@telegram-apps/sdk-react";
import LoginScreen from "./screens/LoginScreen/LoginScreen";

export interface UserData {
  id: string;
  name: string;
}

function App() {
  const [screen, setScreen] = useState("authorized");
  const [startParam, setStartParam] = useState("");

  const { initData } = retrieveLaunchParams();
  const [userData, setUserData] = useState<UserData>({
    id: initData?.user?.id.toString() || "148814881488",
    name: initData?.user?.firstName || "aboba",
  });

  // const [userData, setUserData] = useState<UserData>({
  //   id: "7044289936",
  //   name: "Дмитрий",
  // });

  // const [userData, setUserData] = useState<UserData>({
  //   id: "1421598484",
  //   name: "Дмитрий",
  // });

  initData?.user?.id.toString();

  useEffect(() => {
    const param = initData?.startParam;
    if (param) setStartParam(param);
  }, [initData]);

  const isUserExist = async () => {
    try {
      const body = {
        telegram_id: userData?.id.toString(),
      };
      const response = await api.post("accounts/user-exists/", body);

      return response.data.exists;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const login = async () => {
    const userExists = await isUserExist();
    if (userExists) {
      setScreen("login");
    } else {
      setScreen("welcome");
    }
  };

  useEffect(() => {
    login();
  }, []);

  const appRender = () => {
    switch (screen) {
      case "welcome":
        return <WelcomeScreen setScreen={setScreen} />;
      case "registration":
        return (
          <RegistrationScreen
            setScreen={setScreen}
            userData={userData}
            startParam={startParam}
          />
        );
      case "login":
        return (
          <LoginScreen
            setScreen={() => setScreen("authorized")}
            userData={userData}
          />
        );
      case "pin-code":
        return <PinCodeScreen setScreen={() => setScreen("authorized")} />;
      case "authorized":
        return <Authorized />;
      case "loading":
        return <LoadingScreen />;
      default:
        return <LoadingScreen />;
    }
  };
  return appRender();
}

export default App;
