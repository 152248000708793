import { FC, FunctionComponent } from "react";
import css from "./ButtonWithIcon.module.css";

interface ButtonWithIconProps {
  value: string;
  Icon: FunctionComponent;
}

const ButtonWithIcon: FC<ButtonWithIconProps> = ({ Icon, value }) => {
  return (
    <div className={css.wrapper}>
      <Icon />
      <p className={css.value}>{value}</p>
    </div>
  );
};
export default ButtonWithIcon;
