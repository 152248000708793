import { api } from "../../api/api";
import KeyboardList from "../../components/KeyboardList";

import { FC, useCallback, useEffect, useState } from "react";
import { getCookie, setCookie } from "../../helpers/cockieHelper";

import BackButton from "../../components/UI/Buttons/BackButton/BackButton";
import { boolean } from "@telegram-apps/sdk";
interface PinCodeScreenProps {
  setScreen: () => void;
}

const ChangePasswordScreem: FC<PinCodeScreenProps> = ({ setScreen }) => {
  const [code, setCode] = useState<string>("****");
  const [currentPassword, setCurrentPassword] = useState<string | null>(
    getCookie("password")
  );

  const [title, setTitle] = useState<string>("СТАРЫЙ ПИН-КОД");
  const [isBackButton, setIsBackButton] = useState<boolean>(true);

  const hanleChangePassword = async () => {
    try {
      const body = {
        new_password1: code + code + code,
        new_password2: code + code + code,
      };

      const response = await api.post("accounts/password/change/", body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getCookie("key")?.toString()} `, // Токен из куки
          "Content-Type": "application/json",
        },
      });
      setCookie("password", code, 7);
      console.log("Password changed successfully:", response.data);
    } catch (error: any) {
      console.error("Error during password change:", error);
    }
  };

  useEffect(() => {
    if (code.indexOf("*") === -1) {
      setTimeout(() => {
        if (currentPassword === null) return;
        if (isBackButton) {
          if (code === currentPassword) {
            setIsBackButton(false);
            setTitle("НОВЫЙ ПИН-КОД");
            setCode("****");
          } else {
            setTitle("ПИН-КОД НЕКОРЕКТЕН");
            setCode("****");
          }
        } else {
          hanleChangePassword();
          setScreen();
        }
      }, 100);
    }
  }, [code]);

  const handleDelete = useCallback(() => {
    setCode((prev) => {
      if (prev === "****") return prev;

      const lastDigitIndex = prev.search(/\d(?=\*|$)/);

      if (lastDigitIndex === -1) {
        return prev.slice(0, prev.length - 1) + "*";
      }

      return (
        prev.slice(0, lastDigitIndex) + "*" + prev.slice(lastDigitIndex + 1)
      );
    });
  }, [setCode]);

  const handleAddNumber = useCallback(
    (number: string) => {
      {
        setCode((prev) => {
          const index = prev.indexOf("*");
          if (index !== -1) {
            return (
              prev.substring(0, index) + number + prev.substring(index + 1)
            );
          }
          return prev;
        });
      }
    },
    [setCode]
  );

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <section className="flex flex-col items-center w-full bg-gradient-to-b from-[#9EE449] to-[#232323]  pt-[57px]">
        <div className="text-white text-left w-screen pl-6 -mt-6 mb-6">
          {isBackButton && (
            <BackButton goBack={() => setScreen()} color={"white"} />
          )}
        </div>
        <span className="text-[56px]">{title}</span>
        <span className="text-[150px]">{code}</span>
      </section>
      <KeyboardList onDelete={handleDelete} onAdd={handleAddNumber} />
    </div>
  );
};

export default ChangePasswordScreem;
