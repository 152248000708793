import { FC } from "react";
import css from "./index.module.css";
import cn from "classnames";
import { BonucesTableType } from "../RefCardsList/constants";

interface ModalTableHeadersProps {
  headers: BonucesTableType[];
  handleClicHeaders: (index: number) => void;
}
const ModalTableHeaders: FC<ModalTableHeadersProps> = ({
  headers,

  handleClicHeaders,
}) => {
  return (
    <div className={css.wrapper}>
      <div className={css.elementsWrapper}>
        {headers.map((item, index) => {
          return (
            <p
              key={item.value}
              className={cn(css.element, {
                [css.active]: item.isActive === true,
              })}
              onClick={() => {
                handleClicHeaders(index);
              }}
            >
              {item.label}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default ModalTableHeaders;
