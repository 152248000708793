import { FC } from "react";
import css from "./CopyButton.module.css";
import copy from "./img/copy-icon.svg";
import cn from "classnames";
import { formatNumber } from "../../../../utils/numberFormater";

interface CopyButtonProps {
  value: string;
  backGround?: string;
  type?: "base" | "detailed";
  refersCount?: number;
  bonuces?: string;
}

function truncateString(str: string, count: number): string {
  return str.length > count ? str.slice(0, count) + "..." : str;
}

const CopyButton: FC<CopyButtonProps> = ({
  value,
  backGround,
  type = "base",
  refersCount = 0,
  bonuces = 0,
}) => {
  const copyTextToClipboard = async (text: string) => {
    try {
      alert("Ссылка скопирована");
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Ошибка:", err);
      alert("Попробуйте еще раз");
    }
  };
  const CopyButtonRender = () => {
    switch (type) {
      case "base":
        return (
          <div
            className={cn(css.wrapper, {
              [css.grayGradient]: backGround === "grayGradient",
            })}
            onClick={() => copyTextToClipboard(value)}
          >
            <p className={css.text}>{truncateString(value, 30)}</p>
            <img src={copy} width={24} height={24} alt="copy" />
          </div>
        );
      case "detailed":
        return (
          <div
            className={css.detailedWrap}
            onClick={() => copyTextToClipboard(value)}
          >
            {bonuces && (
              <div>
                <p className={css.title}>{"бонусов".toLocaleUpperCase()}:</p>
                <p>{formatNumber(+bonuces)}</p>
              </div>
            )}
            <div>
              <p className={css.title}>{"Рефералов".toLocaleUpperCase()}:</p>
              <p>{refersCount}</p>
            </div>
            <div>
              <p className={css.title}>{"Реф. Ссылка:".toLocaleUpperCase()}</p>
              <p className={css.text}>{truncateString(value, 15)}</p>
            </div>

            <img src={copy} width={24} height={24} alt="copy" />
          </div>
        );
    }
  };
  return CopyButtonRender();
};
export default CopyButton;
