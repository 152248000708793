import { FC, useEffect, useState } from "react";
import ProfileButton from "../../components/UI/Buttons/ProfileButton/ProfileButton";
import Button from "../../components/UI/Buttons/Button";
import TextInput from "../../components/UI/Inputs/TextInput";
import { ReactComponent as ChangeIcon } from "../../images/icons/change-icon.svg";
import icon from "../../images/icons/default-icon.png";
import css from "./index.module.css";
import { getCookie } from "../../helpers/cockieHelper";
import { IUserData } from "../interfaces";
import { api } from "../../api/api";

interface SettingsScreenProps {
  setFooterVisible: React.Dispatch<React.SetStateAction<boolean>>;

  setWindow: React.Dispatch<React.SetStateAction<string>>;
}

const SettingsScreen: FC<SettingsScreenProps> = ({
  setFooterVisible,

  setWindow,
}) => {
  const [name, setName] = useState<string>(getCookie("name") || "");
  const [avatar, setAvatar] = useState<string>(icon);
  const [uploadImage, setUploaadImage] = useState<File>();
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  const [placehoder, setPlaceholder] = useState<string>("");

  const getUserData = async () => {
    try {
      const response = await api.get(`/profile/me/`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getCookie("key")?.toString()} `,
          "Content-Type": "application/json",
        },
      });

      setAvatar(response.data.profile_pic);
      setPlaceholder(response.data.name);
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userData.profile_pic) {
      setAvatar(userData.profile_pic);
    }
  }, []);

  const handleSave = async () => {
    const formData = new FormData();
    if (name.length > 0 || uploadImage) {
      if (name.length > 0) {
        formData.append("name", name);
      }
      if (uploadImage) {
        formData.append("profile_pic", uploadImage);
      }

      try {
        const response = await api.patch(`/profile/me/`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `,
          },
        });
        setAvatar(
          "https://memback.taskcomplete.ru" + response.data.profile_pic
        );
        setPlaceholder(response.data.name);
        setName("");
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    } else {
      console.log("загрузите картинку или заполните поле Ник");
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploaadImage(file);
      alert("Изображение загружено. Нажмите кнопку сохранить.");
    } else {
      alert("Попробуйте еще раз");
    }
  };

  return (
    <div className={css.settingsWrapper}>
      <p className={css.settingsTitle}>НАСТРОЙКИ</p>
      <div className={css.settingsIconWrap}>
        <img src={avatar} alt="Profile Avatar" className={css.settingsAvatar} />
        <label className={css.changeIconWrap} htmlFor="avatarUpload">
          <ChangeIcon />
          <input
            id="avatarUpload"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </label>
      </div>
      <div className={css.buttons}>
        <div className={css.changeNamneWrapper}>
          <p className={css.label}>НИК</p>
          <TextInput
            placehoder={`#${placehoder}`}
            value={name}
            classes={{
              input: "text-[#7b7b7b]",
            }}
            onChange={setName}
          />
        </div>
        <ProfileButton
          value={"Сменить пин"}
          onClick={() => {
            setFooterVisible(false);
            setWindow("change_pin");
          }}
        />
        <Button
          onClick={() => {
            handleSave();
          }}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default SettingsScreen;
